import config from "../config";

export const fetchSpotifyToken = async () => {
  const response = await fetch(config.API_URL + `/token/spotify`);
  const data = await response.json();
  const newExpirationTime = new Date().getTime() + data.expiresIn * 1000;

  localStorage.setItem('spotifyAccessToken', data.accessToken);
  localStorage.setItem('spotifyTokenExpirationTime', newExpirationTime);

  return data.accessToken;
};

export const getValidSpotifyToken = async () => {
  const accessToken = localStorage.getItem('spotifyAccessToken');
  const expirationTime = parseInt(localStorage.getItem('spotifyTokenExpirationTime'), 10);

  if (!accessToken || !expirationTime || new Date().getTime() > expirationTime) {
    return await fetchSpotifyToken();
  }

  return accessToken;
};

export const fetchSpotifyData = async (query, accessToken) => {
  const searchParameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
  };

  const response = await fetch(`https://api.spotify.com/v1/search?q=${query}&type=track&limit=10`, searchParameters);

  return response.json();
};
