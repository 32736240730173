import React from 'react';
import ThemeController from './ThemeController';

function Navbar() {
    return (
        <div className="flex justify-center">
            <div className="navbar bg-base-300 rounded-2xl w-9/12 mt-4 mb-6 shadow">
                <div className="navbar-start">
                    <div className="dropdown">
                        <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h7" />
                            </svg>
                        </div>
                        <ul
                            tabIndex={0}
                            className="menu menu-sm dropdown-content bg-primary rounded-box z-[1] mt-4 w-52 p-2 shadow">
                            <li><button className='text-base-100' onClick={() => console.log('Music clicked')}>Music</button></li>
                            <li>
                                <button className='text-base-100' onClick={() => console.log('Films clicked')}>
                                    Films
                                    <span className="badge">Soon</span>
                                </button>
                            </li>
                            <li>
                                <button className='text-base-100' onClick={() => console.log('Books clicked')}>
                                    Books
                                    <span className="badge">Soon</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="navbar-center">
                    <a href="/" className="btn btn-ghost text-xl">Artify</a>
                </div>
                <div className="navbar-end">
                    <ThemeController />
                    <div className="dropdown dropdown-end">
                        <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar mr-2">
                            <div className="w-10 rounded-full">
                                <img
                                    alt="Profile button"
                                    src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp" />
                            </div>
                        </div>
                        <ul
                            tabIndex={0}
                            className="menu menu-sm dropdown-content bg-primary rounded-box z-[1] mt-4 w-52 p-2 shadow">
                            <li>
                                <button className="justify-between" onClick={() => console.log('Profile clicked')}>
                                    <span className="text-base-100">Profile</span>
                                    <span className="badge">Soon</span>
                                </button>
                            </li>
                            <li>
                                <button onClick={() => console.log('Sign in clicked')}>
                                    <span className="text-base-100">Sign in</span>
                                    <span className="badge">Soon</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar;