import React, { useState, useEffect, useRef } from 'react';
import config from '../config';
import { fetchSpotifyToken, fetchSpotifyData, getValidSpotifyToken } from '../services/spotifyService';

const Search = () => {
  const [userInput, setUserInput] = useState('');
  const [searchSpotify, setSearchSpotify] = useState([]);
  const [selectedTrack, setSelectedTrack] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const suggestionsRef = useRef(null);

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = localStorage.getItem('spotifyAccessToken');
        if (!token) {
          const newToken = await fetchSpotifyToken();
          localStorage.setItem('spotifyAccessToken', newToken);
        }
      } catch (error) {
        console.error('Error setting token:', error);
      }
    };

    getToken();
  }, []);

  const formatDuration = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleFetchSpotifyData = async (value) => {
    try {
      const accessToken = await getValidSpotifyToken();
      const data = await fetchSpotifyData(value, accessToken);
      const tracks = data.tracks.items.map(item => ({
        name: item.name,
        artists: item.artists.map(artist => artist.name).join(', '),
        album: item.album.name,
        image: item.album.images[0]?.url,
        duration: formatDuration(item.duration_ms),
        releaseDate: item.album.release_date,
        popularity: item.popularity,
        explicit: item.explicit,
      }));
      setSearchSpotify(tracks);
    } catch (error) {
      console.error('Error fetching Spotify data:', error);
    }
  };

  const fetchSuggestions = async (input) => {
    setIsLoading(true);
    try {
      const response = await fetch(config.API_URL + `/ai/music`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: input }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setSuggestions(data.suggestions);
      setUserInput('');
      setSearchSpotify([]);
      suggestionsRef.current.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.error('Failed to fetch suggestions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (value) => {
    setUserInput(value);
    if (value.length > 1) {
      handleFetchSpotifyData(value);
    } else {
      setSearchSpotify([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchSuggestions(
      selectedTrack.name + ' by ' + selectedTrack.artists + ' from ' + selectedTrack.album + ' album'
      + ' released on ' + selectedTrack.releaseDate + ' and a popularity of ' + selectedTrack.popularity + '/100'
    );
  };

  const handleSuggestionClick = (track) => {
    setUserInput(track.name + ' by ' + track.artists);
    setSelectedTrack(track);
  };

  return (
    <div className="flex flex-col pb-10 w-9/12 mx-auto">
      <form onSubmit={handleSubmit} className="bg-base-300 shadow-md rounded-2xl p-8 mb-4">
        <div className="flex flex-col sm:flex-row justify-center mb-4">
          <input
            type="text"
            value={userInput}
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Enter a track"
            className="input w-full max-w-xs mb-2 sm:mb-0 sm:mr-4"
            id="searchInput"
          />
          <button type="submit" className="btn btn-primary flex items-center justify-center">
            {isLoading ? (
              <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white mr-2"></div>
            ) : null}
            {isLoading ? 'Loading...' : 'Get Suggestions'}
          </button>
        </div>
        {searchSpotify.length > 0 && (
          <div className="w-full bg-base-100 shadow-md rounded-2xl p-4 mb-4">
            <ul className="space-y-2">
              {searchSpotify.map((track, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(track)}
                  className="flex items-center cursor-pointer hover:bg-base-300 p-2 rounded"
                >
                  <img src={track.image} alt={track.name} className="w-10 h-10 rounded mr-4 mt-1" />
                  <div className="flex flex-grow flex-col">
                    <span className="font-medium">{track.name}</span>
                    <div className='flex flex-row'>
                      {track.explicit && (
                        <span className="badge badge-neutral mr-2">E</span>
                      )}
                      <span className="font-light">{track.artists}</span>
                    </div>
                  </div>
                  <span className="font-normal ml-auto">{track.duration}</span>
                </li>
              ))}
            </ul>
          </div>
        )}

      </form>

      {suggestions.length === 0 ? null :
        <div ref={suggestionsRef} className="bg-base-300 shadow-md rounded-2xl p-8">
          <ul className="space-y-4">
            {suggestions.map((suggestion, index) => (
              <li key={index} className="p-4 bg-base-100 rounded-lg shadow hover:shadow-md transition-shadow duration-300">
                <div className="text-lg text-primary font-semibold">{suggestion.title}</div>
                <div className="font-bold">{suggestion.artist}</div>
                <div className="font-semibold">{suggestion.album}</div>
                <div className="font-thin">{suggestion.genre}</div>
                <div className="">{suggestion.description}</div>
              </li>
            ))}
          </ul>
        </div>
      }
    </div>
  );
};

export default Search;
